.pflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.pdiv {
  width: 300px;
  height: 350px;
  border-radius: 20px;
  margin: 20px;
}
.pimg {
  width: 300px;
  height: 250px;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.pmain {
  margin-bottom: 30px;
}
.pimg:hover {
  transform: scale(1.05);
}
.pbtn {
  font-size: 18px;
  font-weight: 600;
  color: rgb(8 145 178);
  background-color: #1f2937;
  border: 1px solid rgb(8 145 178);
  border-radius: 2px;
  padding: 2px 5px;
  margin: 5px 10px;
}
.pbtn:hover {
  transform: scale(1.1);
}
.pname {
  color: white;
  margin: 5px 10px;
  font-size: 20px;
  font-weight: 500;
}
