.shead {
  color: white;
  font-size: 40px;
  font-weight: 700;
  text-align: center;
}
.shead1 {
  color: rgb(75 85 99);
  font-size: 28px;
  font-weight: 700;
  text-align: center;
}
.sflex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 40px;
}
.sdiv {
  width: 220px;
  height: 260px;
  border: 2px solid rgb(8 145 178);
  border-radius: 20px;
  padding: 35px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 10px;
}
/* .sgr{
        width: 130px;
        height: 130px; 
        border-radius: 50%;
border: 10px solid gray red;
text-align: center;

        
    } */
.sgr {
  position: relative;
  background-color: #0f0f20;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  padding: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  z-index: 1;
}

.sgr::before {
  content: "";
  position: absolute;
  top: -10px; /* Move 10px outside */
  left: -10px;
  width: calc(100% + 20px); /* Expand width to fit the border */
  height: calc(100% + 20px); /* Expand height to fit the border */
  border-radius: 50%;
  background: conic-gradient(
    gray 0% 20%,
    /* First 20% is gray */ blue 20% 100% /* Remaining 80% is blue */
  );
  z-index: -1; /* Put behind the content */
  margin: 10px;
}

.sdiv2 {
  color: white;
  background-color: rgb(17 24 39);
  border-radius: 50%;
  width: 120px;
  height: 120px;
  text-align: center;
  padding: 20 40px 40px 10px;
  margin: 30px 0px 20px 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  justify-items: center;
  z-index: 2;
  position: relative;
  font-size: 40px;

  /* margin: 10px; */
}

.sdiv2::after {
  content: attr(data-percent) "%"; /* Show the percentage inside the circle */
  position: absolute;
  width: 200px;
  height: 200px;
}

.sdiv3 {
  color: white;
  background-color: rgb(17 24 39);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  padding: 0px;
  margin-top: 20px;
}
