

.custom-tabs .nav-link {
    border: none !important;
  }
  
  .custom-tabs .nav-tabs {
    border-bottom: none !important;
  }
  html, body {
    margin: 0;
    padding: 0;
    overflow-x: hidden;
  }
